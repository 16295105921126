//base url
export const LOGIN_PAGE = 'https://dev-home.phillipsoutsourcing.net/login';
export const MAIN_MENU = 'https://dev-home.phillipsoutsourcing.net/home';
export const API_BASE_URL = 'https://dev-api.phillipsoutsourcing.net';
export const DOCUMENT_BASE_URL = 'https://s3.us-east-1.amazonaws.com/dev-api.phillipsoutsourcing.net';

//Company
export const COMPANIES_URL = '/companies';
export const MAKE_USER_COMPANY_REPRESENTATIVE = '/make-representative';
export const SET_BASE_CURRENCY = '/set-base-currency';
export const DEFAULT_COMPANY_URL = '/default-companies';
export const DASHBOARD_STATISTICS = '/dashboard-statistics';

export const SEARCH_COMPANY_USERS = '/search-company-users';
export const SEARCH_COMPANY = '/search-companies';
export const SEARCH_COMPANY_WORKDAYS = '/search-company-workdays';
export const SAVE_WORK_DAYS = '/save-company-workdays';


//Accounts
export const ACCOUNT_CATEGORIES_URL = '/account-categories';
export const SEARCH_SUB_ACCOUNTS = '/search-sub-accounts';
export const ACCOUNT_URL = '/accounts';
export const ACCOUNT_SUMMARY = '/account-summaries';
export const ASSIGN_ACCOUNT_SUMMARY = '/assign-account-summary';
export const SEARCH_ACCOUNTS = '/search-accounts';
export const SEARCH_EXPENSES_ACCOUNTS = '/search-expenses-accounts';
export const SEARCH_LIABILITY_ACCOUNTS = '/search-liability-accounts';

//Payroll and Items
export const HOLIDAYS = '/holidays';
export const ITEMS_URL = '/items';
export const ITEM_TYPE_URL = '/item-types';
export const SEARCH_ITEMS = '/search-items';
export const SELECTED_PAYROLL_ITEMS = '/selected-payroll-items';
export const SAVE_SELECTED_PAYROLL_ITEMS = '/save-selected-payroll-items';
export const GET_PAYMENT_BATCHES = '/get-payment-batches';
export const GET_PAYROLL_ITEMS = '/get-payroll-items';
export const GET_PAYROLL_ITEM = '/get-payroll-item';
export const GET_GRADE_ITEMS = '/get-grade-items';
export const GET_ACTIVE_ITEMS = '/get-active-items';
export const GET_ITEM_TYPES = '/get-item-types';
export const SAVE_PAYROLL_ITEMS = '/save-payroll-items';
export const SCAN_PAYROLL_ITEMS = '/scan-payroll-items';
export const IMPORT_PAYROLL_ITEMS = '/import-payroll-items';

//users
export const SEARCH_USERS = '/search-users';

//general urls
export const INDUSTRIES_URL = '/industries';
export const BUSINESS_TYPES_URL = '/business-types';
export const COUNTRIES_URL = '/countries';

//data urls
export const EXPORT_ACCOUNT_TEMPLATE = '/export-accounts-template';
export const EXPORT_PAYMENT_APPROVAL_LIST = '/export-payment-approval-list';
export const EXPORT_CONFIRMATION_LIST = '/export-confirmation-list';
export const EXPORT_ITEM_TEMPLATE = '/export-items-template';
export const SCAN_ACCOUNT_FILE = '/scan-accounts-files';
export const SCAN_ITEM_FILE = '/scan-items-files';
export const IMPORT_ACCOUNT_FILE = '/import-accounts-files';
export const IMPORT_ITEM_FILE = '/import-items-files';

//system settings
export const GET_BUSINESS_PERIOD = '/get-business-period';
export const SAVE_BUSINESS_PERIOD = '/save-business-period';
export const SAVE_SCHEDULED_REPORTS = '/save-scheduled-reports';
export const GET_TRACKING_ACCOUNT_SETTINGS = '/get-tracking-accounts';
export const SAVE_TRACKING_ACCOUNT_SETTINGS = '/save-tracking-accounts';
export const GET_PAYROLL_COMPUTATIONS = '/get-payroll-computations';
export const REMOVE_COMPUTATION = '/remove-computations';
export const TOGGLE_COMPUTATION = '/toggle-computation';
export const GET_COMPUTATIONS_VARIABLES = '/get-computations-variables';
export const SAVE_PAYROLL_COMPUTATIONS = '/save-payroll-computations';
export const GET_ACTIVE_FORMULAS = '/get-active-formulas';
export const ADD_PAYROLL_APPROVER = '/add-payroll-approver';
export const ADD_REMITTANCE_ITEM = '/add-remittance-item';
export const ADD_EXCLUDED_CLIENT = '/add-excluded-client';
export const ADD_PAYROLL_NOTIFIER = '/add-payroll-notifier';
export const ADD_VARIATION_NOTIFIER = '/add-variation-notifier';
export const ADD_PAYMENT_NOTIFIER = '/add-payment-notifier';
export const ADD_INIT_PAYMENT_NOTIFIER = '/add-init-payment-notifier';
export const ADD_VARIATION_APPROVER = '/add-variation-approver';
export const GET_PAYROLL_APPROVERS = '/get-payroll-approvers';
export const GET_REMITTANCE_ITEMS = '/get-remittance-items';
export const GET_EXCLUDED_CLIENTS = '/get-excluded-clients';
export const GET_PAYROLL_NOTIFIERS = '/get-payroll-notifiers';
export const GET_VARIATION_NOTIFIERS = '/get-variation-notifiers';
export const GET_PAYMENT_NOTIFIERS = '/get-payment-notifiers';
export const GET_INIT_PAYMENT_NOTIFIERS = '/get-init-payment-notifiers';
export const GET_VARIATION_APPROVERS = '/get-variation-approvers';
export const SAVE_APPROVER_ORDER = '/save-approver-order';
export const SAVE_VARIATION_NOTIFIER_ORDER = '/save-variation-notifier-order';
export const SAVE_PAYROLL_NOTIFICATION_ORDER = '/save-payroll-notifier-order';
export const SAVE_PAYMENT_NOTIFICATION_ORDER = '/save-payment-notifier-order';
export const SAVE_INIT_PAYMENT_NOTIFICATION_ORDER = '/save-init-payment-notifier-order';
export const SAVE_NOTIFIER_ORDER = '/save-notifier-order';
export const SAVE_VARIATION_APPROVER_ORDER = '/save-variation-approver-order';
export const REMOVE_VARIATION_APPROVER = '/remove-variation-approver';
export const REMOVE_PAYROLL_APPROVER = '/remove-payroll-approver';
export const REMOVE_REMITTANCE_ITEM = '/remove-remittance-item';
export const REMOVE_EXCLUDED_CLIENT = '/remove-excluded-client';
export const REMOVE_PAYROLL_NOTIFIER = '/remove-payroll-notifier';
export const REMOVE_VARIATION_NOTIFIER = '/remove-variation-notifier';
export const REMOVE_PAYMENT_NOTIFIER = '/remove-payment-notifier';
export const REMOVE_INIT_PAYMENT_NOTIFIER = '/remove-init-payment-notifier';

export const GET_TAXES = '/get-taxes';
export const REMOVE_TAX_ITEM = '/remove-tax-item';
export const GET_TAX_ITEMS = '/get-tax-items';
export const ADD_TAX_ITEM = '/add-tax-item';

export const GET_USER_AUTHORIZATION = '/get-user-authorization';

export const EXCHANGE_RATES = '/exchange-rates';
export const CURRENCY_SYMBOLS = '/currency-symbols';

//reports

export const GET_PAYROLL_RATES = '/get-payroll-rates';
export const UPDATE_PAYROLL_RATES = '/update-payroll-rates';
export const GET_PAYROLL_CLIENTS = '/get-payroll-clients';
export const GET_APPROVED_PAYROLLS = '/get-approved-payrolls';
export const GET_PAYABLE_ITEMS = '/get-payable-items';
export const PAYROLL_PAYABLES = '/payroll-payables';
export const MANAGEMENT_PAYROLLS = '/management-payrolls';
export const EXPORT_JOURNAL = '/export-journal';
export const GET_PAYROLL_APPROVAL_STATUS = '/get-payroll-approval-status';

export const GET_PAYROLL_JOURNAL_ENTRIES = '/get-payroll-journal-entries';
export const GET_PAYROLL_JOURNAL_SUMMARIES = '/get-payroll-journal-summaries';

export const POST_JOURNAL_ENTRIES = '/post-journal-entries';

export const SEARCH_COMPANY_EMPLOYEES = '/search-company-employees';
export const EXCLUDE_EMPLOYEE_APPROVAL = '/exclude-employee-approval';
export const EXCLUDE_EMPLOYEE_CONFIRMATION = '/exclude-employee-confirmation';
export const EXCLUDE_EMPLOYEE_CONFIRMATION_BY_COMPANY = '/exclude-employee-confirmation-by-company';
export const REMOVE_EXCLUSION_FROM_APPROVAL = '/remove-exclusion-from-approval';
export const REMOVE_EXCLUSION_FROM_CONFIRMATION = '/remove-exclusion-from-confirmation';
export const CLEAR_APPROVAL_EXCLUSION_LIST = '/clear-approval-exclusion-list';
export const CLEAR_CONFIRMATION_EXCLUSION_LIST = '/clear-confirmation-exclusion-list';
export const GET_PAYMENT_EXCLUSION_LIST = '/get-payment-exclusions';
export const GET_CONFIRMATION_EXCLUSION_LIST = '/get-confirmation-exclusions';
export const EXPORT_PAYROLL_REPORT = '/export-payroll-report';
export const EXPORT_PENDING_PAYMENT_REPORT = '/export-pending-payment-report';
export const APPROVE_ITEM_PAYMENT = '/approve-item-payment';
export const CONFIRM_ITEM_PAYMENT = '/confirm-item-payment';
